import mixpanel from 'mixpanel-browser';
import { getUserRole } from 'common/utils/permissions';

export function initMixpanel() {
  if (!process.env.CROWE_MIXPANEL_TOKEN) {
    console.warn('Mixpanel token is missing. Initialization skipped.');
    return;
  }
  try {
    mixpanel.init(process.env.CROWE_MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: true,
      ignore_dnt: true,
      persistence: 'localStorage',
    });
  } catch (error) {
    console.error('Mixpanel initialization failed:', error);
  }
}

export const trackEvent = (eventName, properties) => {
  if (typeof mixpanel?.track === 'function') {
    try {
      mixpanel.track(eventName, {
        ...properties,
      });
    } catch (error) {
      console.error('Mixpanel tracking failed:', error);
    }
  } else {
    console.warn('Mixpanel is not available, skipping tracking.');
  }
};

export const trackDownload = (title, url, resourceIsVideo, link) => {
  if (resourceIsVideo) {
    window.location.href = link;
    return;
  }

  trackEvent('Resource Downloaded', {
    Title: title,
    Url: url,
    user_id: mixpanel.get_distinct_id?.() || 'unknown',
    timestamp: new Date().toISOString(),
  });

  window.open(url, '_blank');
};

export const trackLogin = (token, userId) => {
  if (typeof mixpanel?.track === 'function' && typeof mixpanel?.identify === 'function') {
    try {
      const role = getUserRole(token) || 'Unknown'; // Fallback to avoid undefined Role

      mixpanel.identify(userId);
      mixpanel.track('User Logged In', {
        Role: role,
        id: userId,
      });
    } catch (error) {
      console.error('Mixpanel tracking failed:', error);
    }
  } else {
    console.warn('Mixpanel is not available, skipping login tracking.');
  }
};
