import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateUser } from 'services/user';
import UserProfile from 'common/components/UserProfileForm/UserProfile';
import { foreignDeskRole } from 'common/constants/forms';
import { useStore } from 'context/Store';
import NoResults from 'common/components/NoResults/NoResults';
import { useTranslation } from 'react-i18next';
import { FOREIGN_DESK_REPRESENTATIVE_ID } from 'common/constants/foreignDesks';
import { getUserById } from 'services/user';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from 'common/constants/toast';
import { useGetById } from 'hooks/useGetById';

const UserEdit = props => {
  const [state] = useStore();

  const { hasFirmAdminPermission, hasAdminPermission, currentUser } = state;

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const { data: user } = useGetById(getUserById, id);

  const { t } = useTranslation('admin');
  const { t: gt } = useTranslation('global');

  const update = async params => {
    setLoading(true);
    try {
      //. network foreign role
      const professionalData = params.professionalData || {};
      const { networkRole } = professionalData;

      var tmpRole = params.network_roles;

      if (networkRole) {
        if (networkRole[foreignDeskRole]) {
          tmpRole.push(foreignDeskRole);
        } else {
          tmpRole = tmpRole.filter(rol => rol != foreignDeskRole);
        }
      }

      if (!networkRole[FOREIGN_DESK_REPRESENTATIVE_ID]) {
        params.lang = '';
      }

      await updateUser(user.id, Object.assign({}, params));
      toast.success(t('updated'), TOAST_CONFIG);
      setLoading(false);
    } catch (error) {
      toast.error(gt('errorMessage'), TOAST_CONFIG);
      setLoading(false);
    }
  };

  const removePhoto = () => {
    return updateUser(user.id, { profile_photo_url: null });
  };

  const removeCV = () => {
    return updateUser(user.id, { cv_url: null });
  };

  if (
    (user && currentUser.id !== user.id && !hasFirmAdminPermission) ||
    (user && hasFirmAdminPermission && !hasAdminPermission && currentUser.firm_id !== user.firm_id)
  )
    return (
      <NoResults
        icon="fa-sharp fa-solid fa-shield"
        title={t('unauthorizedAccess')}
        comment={t('noAuth')}
      />
    );

  return (
    <>
      <Helmet>
        <title>{`Crowe Global - Edit User: ${user?.id || 'unknown'}`}</title>
      </Helmet>
      {user && (
        <UserProfile
          user={user}
          update={update}
          removePhoto={removePhoto}
          removeCV={removeCV}
          loading={loading}
          {...props}
        />
      )}
    </>
  );
};

export default UserEdit;
