import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { getResourcesPageData } from 'services/section';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { getResourcesByTag } from 'services/resources';
import Resource from 'common/components/Layout/Resource';
import LayoutFilters from 'common/components/Layout/LayoutFilters';
import NoResults from 'common/components/NoResults/NoResults';
import { useGetById } from 'hooks/useGetById';
import { useGet } from 'hooks/useGet';
import { getTags } from 'services/tags';
import { tagsHandler } from 'common/helpers/tags';
import Pagination from 'common/components/Pagination/Pagination';
import { capitalize } from 'common/utils/strings';
import ResourceSkeleton from 'common/components/Skeletons/ResourceSkeleton';
import Help from 'common/components/Registration/Help';

const ResourceListPage = () => {
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredResources, setFilteredResources] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filterValues, setFilterValues] = useState({
    topics: [],
    type: '',
    search: '',
    sort: '',
  });

  const { t } = useTranslation('layouts');
  const location = useLocation();
  const { slug } = useParams();
  const { data } = useGetById(getResourcesPageData, slug);
  const { data: tags } = useGet(getTags);

  const tagHelper = tagsHandler(tags);

  // items per page
  const pageSize = 20;

  const handleParameters = () => {
    let topics = [];
    let type = '';
    let sort = '';
    let search = '';

    let parameters = location.search?.split('?')[1].split('&');

    parameters.length &&
      parameters.map(parameter => {
        if (parameter.includes('topic=')) {
          topics = parameter
            .split('topic=')[1]
            .split(',')
            .map(topic => ({ label: tagHelper[topic], value: topic }));
        }
        if (parameter.includes('type=')) {
          type = parameter.split('type=')[1];
          type = { label: tagHelper[type], value: type };
        }
        if (parameter.includes('sort=')) {
          sort = parameter.split('sort=')[1];
        }
        if (parameter.includes('q=')) {
          search = {
            label: parameter.split('q=')[1],
            value: parameter.split('q=')[1],
          };
        }
      });

    setFilterValues({ topics, type, sort, search });
  };

  const handleResources = async () => {
    try {
      setIsLoading(true);
      let data = await getResourcesByTag(
        [data[0].metadata.tags.sys.id, ...filterValues.topics.map(topic => topic.value)]
          .filter(tag => tag.length)
          .join(),
        filterValues.type.value,
        filterValues.search.value,
        page * pageSize,
        filterValues.sort?.value
      );
      setResources(data.items);
      setFilteredResources(data.items);
      setPageCount(Math.ceil(data.total / pageSize));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleDefaultTag = () => {
    if (data?.length) {
      return data[0].metadata.tags.length ? data[0].metadata.tags[0].sys.id : '';
    }
    return 'noTagYet';
  };

  useEffect(() => {
    if (location && location.search && tags) {
      handleParameters();
    } else {
      setFilterValues({
        topics: [],
        type: '',
        search: '',
        sort: '',
      });
      setPage(0);
      setPageCount(0);
    }
  }, [slug, location, tags]);

  useEffect(() => {
    if (data?.length && slug) {
      handleResources();
    }
  }, [slug, data, page, filterValues]);

  return (
    <>
      <Helmet>
        <title>
          {data?.length &&
            `Crowe Global - ${
              data[0].metadata.tags.length ? capitalize(data[0].metadata.tags[0].sys.id) : ''
            } Resources`}
        </title>
      </Helmet>
      <div className="resources__container">
        {!!data?.length && <h1 className="resources__title">{data?.[0].fields.title}</h1>}
        <div className="resources__help">
          <Help
            text="Enter text or select from each field below to find what you are looking for."
            icon="fa-solid fa-circle-info"
          />
        </div>
        <LayoutFilters
          values={filterValues}
          setValues={setFilterValues}
          items={resources}
          setFilteredItems={setFilteredResources}
          searchPlaceholder={t('search')}
          pageSize={pageSize}
          setPageCount={setPageCount}
          sectionTag={handleDefaultTag()}
          page={page}
          setPage={setPage}
          getService={getResourcesByTag}
          withTypes={true}
        />
        {isLoading && (
          <div className="resources__tiles mb-20px">
            <ResourceSkeleton />
            <ResourceSkeleton />
            <ResourceSkeleton />
            <ResourceSkeleton />
            <ResourceSkeleton />
            <ResourceSkeleton />
            <ResourceSkeleton />
            <ResourceSkeleton />
          </div>
        )}
        <div className={isLoading ? 'hidden' : ''}>
          <div className="resources__tiles">
            {!!filteredResources?.length ? (
              filteredResources.map(resource => (
                <Resource resource={resource} key={resource.sys.id} tagList={tagHelper} />
              ))
            ) : (
              <NoResults icon="icon-search" title={t('noResources')} comment={t('try')} />
            )}
          </div>
          {pageCount > 1 && <Pagination total={pageCount} setPage={setPage} activePage={page} />}
        </div>
      </div>
    </>
  );
};

export default ResourceListPage;
