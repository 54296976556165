import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import 'url-search-params-polyfill';
import { LoginForm } from 'common/components/Login/LoginForm';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import logo from 'assets/crowe-logo-white.svg';
import video from 'assets/videos/1-Login-1920x1080.mp4';
import { getToken } from 'common/utils/localStorage';
import Cookies from 'common/components/Cookies/Cookies';
import { useStore } from 'context/Store';

const Login = () => {
  const [cookieModal, setCookieModal] = useState(true);

  const [state] = useStore();
  const { acceptedCookies, authCookies } = state;

  const { t } = useTranslation('login');
  const location = useLocation();

  const queryString = new URLSearchParams(location.search);
  const registerSuccess = queryString.get('registerSuccess');
  const token = getToken();

  if (token && acceptedCookies && authCookies) {
    return <Navigate to="/home" />;
  }

  return (
    <>
      <Helmet>
        <title>Crowe Global - Login</title>
      </Helmet>
      <video className="login__video" autoPlay muted loop webkit-playsinline="true" playsInline>
        <source src={video} type="video/mp4" />
      </video>
      <div className="login__container">
        <img className="login__logo" src={logo} alt="Crowe" />
        <h1 className="login__title">{t('loginTitle')}</h1>
        {registerSuccess && <p className="login__success-msg">{t('registerSuccess')}</p>}
        <LoginForm t={t} setCookieModal={setCookieModal} />
        {!process.env.CROWE_IS_IN_MAINTENANCE && (
          <div className="login__widget">
            <Link to="/forgot-password" className="login__btn login__btn--disabled mb-20px">
              {t('login:resetPassword')}
            </Link>

            <a href="mailto:directory@crowe.org" className="login__link login__link--red">
              {t('help')}
            </a>
          </div>
        )}
      </div>
      {!acceptedCookies && cookieModal && <Cookies setCookieModal={setCookieModal} />}
    </>
  );
};

export default Login;
