import { useState, useEffect, useCallback } from 'react';
import { useStore } from 'context/Store';
import { getFirms } from 'services/firm';
import DirectoryList from 'common/components/Directory/DirectoryList';
import FirmCard from 'common/components/Directory/Cards/FirmCard';
import FirmDirectoryFilters from 'common/components/Directory/Filters/FirmDirectoryFilters';
import CollapsibleMenu from 'common/components/CollapsibleMenu/CollapsibleMenu';
import { useFilters } from 'hooks/useFilters';
import FirmCardSkeleton from 'common/components/Skeletons/FirmCardSkeleton';
import { trackEvent } from 'common/utils/mixpanel';

const PAGE_SIZE = 20;

const FirmDirectory = () => {
  const [offset, setOffset] = useState(0);

  const [state, dispatch] = useStore();

  const {
    specialty_categories,
    specialties,
    industries,
    countries,
    cities,
    vlpServices,
    firmFilters,
  } = state;

  const {
    items: { firms },
    count,
    loading,
    error,
    handleFilters,
  } = useFilters(getFirms, offset, firmFilters);

  const handleSelectPage = async pageNumber => {
    setOffset((pageNumber - 1) * PAGE_SIZE);
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const trackFilters = (filterName, value) => {
    trackEvent('GD Member Firms Filter Used', {
      'Filter Name': filterName,
      'Filter Value': value?.value || 'N/A',
    });
  };

  const handleChange = useCallback(
    (filterName, value) => {
      let newFilters = {};
      setOffset(0);

      if (filterName === null) {
        // Clear all filters
        newFilters = {};
      } else if (!!filterName) {
        newFilters = { ...firmFilters, [filterName]: value?.value };
      }
      if (filterName === 'specialty_category') {
        newFilters = { ...firmFilters, specialty_category: value?.value, specialties: null };
      }
      if (filterName === 'country') {
        newFilters = { ...firmFilters, country: value?.value, city: null };
      }
      if (filterName === 'industries') {
        newFilters = { ...firmFilters, industries: value.map(option => option.value) };
      }
      if (filterName === 'vlp_services') {
        newFilters = { ...firmFilters, [filterName]: value.map(option => option.value) };
      }
      dispatch({ type: 'SET_FIRM_FILTERS', payload: { ...newFilters } });
      trackFilters(filterName, value);
    },
    [firmFilters, dispatch]
  );

  useEffect(() => {
    handleFilters();
  }, [offset, firmFilters]);

  return (
    <div className="mt-20px directory__list-container">
      <div className="detail__widget-container--large">
        <div className="bg-white rounded mb-20px">
          <CollapsibleMenu filterType="firm">
            <FirmDirectoryFilters
              totalFirms={count}
              handleChange={handleChange}
              countries={Object.keys(countries)}
              cities={
                firmFilters.country
                  ? countries[firmFilters.country]
                  : Object.keys(cities).filter(city => city)
              }
              industries={industries}
              specialtyCategories={specialty_categories}
              specialties={specialties}
              vlpServices={vlpServices}
              filters={firmFilters}
              error={error}
            />
          </CollapsibleMenu>
        </div>
        <DirectoryList
          skeleton={FirmCardSkeleton}
          records={firms}
          recordComponent={FirmCard}
          recordCount={count}
          isFetching={loading}
          pageSize={PAGE_SIZE}
          offset={offset}
          onSelectPage={handleSelectPage}
          countLabelKey="firmCount"
          noResultsIcon="work"
        />
      </div>
    </div>
  );
};

export default FirmDirectory;
