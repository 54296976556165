import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirm } from 'services/firm';
import FirmDetail from '../../common/components/FirmDetail/FirmDetail';
const Firm = () => {
  const [firmData, setFirmData] = useState({});

  const { id } = useParams();

  const handleFirm = async () => {
    const currentFirm = await getFirm(id);
    setFirmData(currentFirm);
  };

  useEffect(() => {
    handleFirm();
  }, [id]);

  const { firm, offices, contacts, staff, capabilities, industries, vlp_services } = firmData;

  return (
    <>
      <Helmet>
        <title>{`Crowe Global - Firm: ${firm?.name}`}</title>
      </Helmet>
      {firm && (
        <FirmDetail
          firm={firm}
          offices={offices}
          staff={staff}
          keyContacts={contacts}
          capabilities={capabilities}
          industries={industries}
          vlpServices={vlp_services}
          country={firm.country}
          country_code={firm.country_code}
        />
      )}
    </>
  );
};

export default Firm;
