import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { getEvents } from 'services/events';
import { getPastEvents } from 'services/events';
import { getLastThreeYears } from 'common/helpers/date';

const EventsFilters = ({ events, setFilteredEvents, tab }) => {
  const [values, setValues] = useState({
    year: '',
    format: '',
  });

  const { t } = useTranslation('events');

  const handleChange = (name, e) => {
    if (!e) {
      return setValues({ ...values, [name]: '' });
    }
    setValues({ ...values, [name]: e.value });
  };

  const handleFilters = async () => {
    try {
      let data;
      if (tab === 0) {
        data = await getEvents(values.year, values.format);
      } else {
        data = await getPastEvents(values.year, values.format);
      }
      setFilteredEvents(data);
    } catch (error) {}
  };

  const formats = [
    { label: 'Virtual', value: '1' },
    { label: 'In Person', value: '0' },
  ];

  const years = [
    ...getLastThreeYears().map(year => ({ label: year, value: year })),
    { label: 'All', value: '' },
  ];

  useEffect(() => {
    handleFilters();
  }, [values, events]);

  return (
    <div className="filter__container mt-10px">
      <label className="filter__label">{t('filterBy')}</label>
      <div className="filter__field">
        <Select
          options={formats}
          onChange={e => handleChange('format', e)}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder={t('format')}
          isClearable
        />
        <i className="icon-arrowDown filter__icon" />
      </div>
      {tab === 1 && (
        <div className="filter__btns">
          {years.map(year => (
            <button
              key={year.value}
              className={`filter__btn ${values.year === year.value ? 'filter__btn--active' : ''}`}
              onClick={() => handleChange('year', year)}
            >
              {year.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventsFilters;
