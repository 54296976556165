import { get, put } from 'common/api';
import axios from 'axios';
import { uploadFiles } from 'services/upload';

export const getFirm = async (id = '', filters = '') => {
  try {
    const res = await get(
      `/firms/${id}${filters ? '?filters=' + encodeURIComponent(JSON.stringify(filters)) : ''}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getSpecialtyCategories = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/specialty_categories`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getSpecialties = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/specialties`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getIndustries = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/industries`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getCertifications = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/certifications`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getVLPServices = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/vlp_services`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getFirms = async (offset = 0, filters = {}, specialties, limit = 20) => {
  let newFilters = { ...filters };
  if (specialties) {
    if (filters.specialty_category && !filters.specialties) {
      newFilters.specialties = specialties
        .filter(specialty => specialty.category_id === filters.specialty_category)
        .map(specialty => specialty.id);
    } else if (filters.specialties) {
      newFilters.specialties = [filters.specialties];
    }
  }
  try {
    const response = await get(
      `/directory/firms?offset=${offset}&filters=${encodeURIComponent(
        JSON.stringify(newFilters)
      )}&limit=${limit}`
    );
    return response;
  } catch (err) {
    return err.errors.message;
  }
};

export const updateFirm = async (id, params) => {
  try {
    const { brochure } = params;

    let newFirmData = {
      ...params,
    };

    if (brochure?.length) {
      const results = await uploadFiles({ files: brochure[0] });

      if (results.length) {
        const file = results[0];
        const fileUrl = file.s3Path || file.path;
        const fileName = file.originalname;
        newFirmData = { ...newFirmData, brochure_url: fileUrl, brochure_file_name: fileName };
      }
    }
    await put(`/firms/${id}`, newFirmData);
  } catch (err) {
    throw new Error(err);
  }
};
