import { useState } from 'react';
import { resourceIconHandler } from 'common/helpers/file';
import Message from 'common/components/Layout/Message';
import Prompt from 'common/components/Prompt/Prompt';
import { isVideo } from 'common/helpers/file';
import { Link } from 'react-router-dom';
import { trackDownload } from 'common/utils/mixpanel';

const ListResource = ({ resource, index }) => {
  const [prompt, setPrompt] = useState(false);

  const url = resource?.fields?.url || resource.fields?.media?.fields.file.url;
  const link = `/resource/${resource.fields.slug ?? `.${resource.sys.id}`}`;

  const resourceType = resource.fields.resourceType;

  const togglePrompt = () => {
    setPrompt(!prompt);
  };

  const {
    fields: { title, external },
  } = resource;

  const handleLink = () => {
    const hasVideoFormat = isVideo(resourceType);

    return external ? (
      <>
        <div className="cursor-pointer" onClick={togglePrompt}>
          {resourceIconHandler(resourceType)}
        </div>
        <button className="widget__description ellipsis-sl mx-10px" onClick={togglePrompt}>
          {resource.fields.title}
        </button>
        {prompt && (
          <Prompt>
            <Message url={url} handleClose={togglePrompt} />
          </Prompt>
        )}
      </>
    ) : hasVideoFormat ? (
      <>
        <Link to={link}>{resourceIconHandler(resourceType)}</Link>
        <div className="d-flex-col mx-10px">
          <Link
            to={link}
            className="text-decoration-none text-black lh-sm"
          >
            <p className="widget__description ellipsis-sl">{resource.fields.title}</p>
          </Link>
        </div>
      </>
    ) : (
      <>
        <div onClick={() => trackDownload(title, url, hasVideoFormat, link)}>
          {resourceIconHandler(resourceType)}
        </div>
        <div className="d-flex-col mx-10px">
          <div
            className="text-decoration-none text-black lh-sm"
            onClick={() => trackDownload(title, url, hasVideoFormat, link)}
          >
            <p className="widget__description ellipsis-sl">{title}</p>
          </div>
        </div>
      </>
    );
  };

  if (!url) return null;

  return <div className={`list__item ${index ? 'border-top' : ''}`}>{handleLink()}</div>;
};

export default ListResource;
