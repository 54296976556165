import { useEffect } from 'react';
import Select from 'react-select';
import { toOptions } from '../../../helpers/options';
import { useTranslation } from 'react-i18next';
import { roleIdsToNames, roleIdToName } from 'common/utils/role';
import { sortByValue } from 'common/utils/sort';
import Help from 'common/components/Registration/Help';
import { useLocation } from 'react-router-dom';
import { useStore } from 'context/Store';
import mixpanel from 'mixpanel-browser';
import { trackEvent } from 'common/utils/mixpanel';

const FirmDirectoryFilters = ({
  filters,
  countries,
  cities,
  industries,
  specialtyCategories,
  specialties,
  vlpServices,
  totalFirms,
  handleChange,
  error,
}) => {
  const { t } = useTranslation('filters');
  const location = useLocation();

  const [state, dispatch] = useStore();

  const handleClear = () => {
    handleChange(null, {});
    trackEvent('GD Member Firms Filters Cleared', {});
  };

  const countryOptions = toOptions(countries);
  const cityOptions = toOptions(cities);
  const specialtyCategoryOptions = sortByValue(specialtyCategories, 'name').map(category => ({
    label: category.name,
    value: category.id,
  }));

  const industryOptions = industries.map(industry => ({
    label: industry.name,
    value: industry.id,
  }));

  const vlpServicesOptions = vlpServices.map(service => ({
    label: service.name,
    value: service.id,
  }));

  let selectedSpecialtyCategory = filters.specialty_category;

  let specialtiesOptions = sortByValue(specialties, 'name')
    .filter(specialty => specialty.category_id === selectedSpecialtyCategory)
    .map(specialty => ({
      label: specialty.name,
      value: specialty.id,
    }));

  const handleURLParameters = () => {
    const search = location.search?.split('?q=')[1];
    if (search) {
      dispatch({
        type: 'SET_FIRM_FILTERS',
        payload: { ...filters, name: search.replace(/%20/g, ' ') },
      });
    }
  };

  useEffect(() => {
    handleURLParameters();
  }, [location]);

  return (
    <>
      <div className="directory__help">
        <Help
          text="Enter text or select from each field below to find the firm you are looking for."
          icon="fa-solid fa-circle-info"
        />
      </div>
      <div className="filters__container">
        <div className="filters__column">
          <label className="filters__label">{t('filterBy')}: </label>
          <div className="filters__select">
            <Select
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              options={countryOptions}
              placeholder={t('country')}
              onChange={e => handleChange('country', e)}
              value={filters.country ? { value: filters.country, label: filters.country } : ''}
            />
          </div>
          <div className="filters__select">
            <Select
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              options={cityOptions}
              placeholder={t('city')}
              onChange={e => handleChange('city', e)}
              value={filters.city ? { value: filters.city, label: filters.city } : null}
            />
          </div>
        </div>
        <div className="filters__column filters__column--mt">
          <div className="filters__select">
            <Select
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              options={specialtyCategoryOptions}
              placeholder={t('specialty')}
              onChange={e => handleChange('specialty_category', e)}
              value={
                filters.specialty_category
                  ? {
                      value: filters.specialty_category,
                      label: roleIdToName(filters.specialty_category, specialtyCategories),
                    }
                  : null
              }
            />
          </div>
          <div className="filters__select">
            <Select
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              options={specialtiesOptions}
              placeholder={t('subSpecialty')}
              onChange={e => handleChange('specialties', e)}
              value={
                filters.specialties
                  ? {
                      value: filters.specialties,
                      label: roleIdToName(filters.specialties, specialties),
                    }
                  : null
              }
            />
          </div>
          <div className="filters__select">
            <Select
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              options={industryOptions}
              placeholder={t('industryExperience')}
              isMulti
              onChange={e => handleChange('industries', e)}
              value={filters.industries ? roleIdsToNames(filters.industries, industries) : null}
            />
          </div>
          <div className="filters__select">
            <Select
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              options={vlpServicesOptions}
              placeholder={t('vlp')}
              onChange={e => handleChange('vlp_services', e)}
              isMulti
              value={
                filters.vlp_services ? roleIdsToNames(filters.vlp_services, vlpServices) : null
              }
            />
          </div>
        </div>
        <div className="filters__column">
          <div className="d-flex-col">
            <label className="filters__label">{t('searchBy')}:</label>
            <input
              className="form-control fs-16px fw-400 h-34px filters__input"
              type="text"
              placeholder="Firm Name"
              value={filters.name || ''}
              onChange={e => handleChange('name', { value: e.target.value })}
            />
          </div>
          <div className="w-100 fs-16px filters__btn-container filters__btn-container--mt">
            {error && <span className="input__error fs-14px">{t('error')}</span>}
            <span>
              {t('totalFirms')} {totalFirms}
            </span>
            <div className="btn btn-primary filters__btn">
              <a onClick={handleClear}>
                <small>{t('clearFilters')}</small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirmDirectoryFilters;
