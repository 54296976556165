import { FIRM_ADMIN_PERMISSIONS, ADMIN_PERMISSIONS } from 'common/permissions';
import { parseJwt } from 'common/utils/jwt';
import { hasPermission } from 'common/utils/auth';

export const getUserRole = token => {
  const permissions = parseJwt(token).permissions;

  if (hasPermission(permissions, ...ADMIN_PERMISSIONS)) {
    return 'CG Admin';
  } else if (hasPermission(permissions, ...FIRM_ADMIN_PERMISSIONS)) {
    return 'Firm Admin';
  } else {
    return 'Member';
  }
};
