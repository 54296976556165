import { useEffect, useState, useRef } from 'react';
import { getSearchItems } from 'services/search';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { Link } from 'react-router-dom';
import Flag from 'common/components/Flag/Flag';
import { resourceIconHandler } from 'common/helpers/file';

const Search = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchItems, setSearchItems] = useState([]);

  const dropdownRef = useRef(null);

  const handleClose = () => {
    setSearchItems([]);
    setSearchValue('');
  };

  const handleChange = e => {
    setSearchValue(e.target.value);
  };

  const fetchItems = async () => {
    if (!searchValue) {
      return setSearchItems([]);
    }
    const items = await getSearchItems(searchValue, searchType);

    setSearchItems([...items.filter(item => !!item.metadata.tags.length)]);
  };

  const handleLink = item => {
    if (item) {
      const helper = {
        article: `/articles/.${item.sys?.id}`,
        resource: `/resource/.${item.sys?.id}`,
        member: `/directory/members/${item?.id}`,
        firm: `/directory/firms/${item?.id}`,
      };

      return helper[searchType];
    }
  };

  useEffect(() => {
    fetchItems();
  }, [searchValue, searchType]);

  useOnClickOutside(dropdownRef, handleClose);

  return (
    <div className="search__container">
      <div className="search__elements" ref={dropdownRef}>
        <div className="search__input-container">
          <input
            className={`form-control search__input ${!searchType ? 'search__input--disabled' : ''}`}
            type="text"
            name="search"
            value={searchValue}
            onChange={handleChange}
            disabled={!searchType}
            placeholder={`${
              searchType
                ? `Search for ${searchType} or choose another option`
                : 'Select Articles or Downloads to Search'
            }`}
          />
          <div className="search__buttons">
            <div
              className={`search__button ${
                searchType === 'article' ? 'search__button--active' : ''
              }`}
              onClick={() => setSearchType('article')}
            >
              ARTICLES
            </div>

            <div
              className={`search__button ${
                searchType === 'resource' ? 'search__button--active' : ''
              }`}
              onClick={() => setSearchType('resource')}
            >
              DOWNLOADS
            </div>

            {/* <div
              className={`search__button ${
                searchType === 'member' ? 'search__button--active' : ''
              }`}
              onClick={() => setSearchType('member')}
            >
              PEOPLE
            </div>
            <div
              className={`search__button ${searchType === 'firm' ? 'search__button--active' : ''}`}
              onClick={() => setSearchType('firm')}
            >
              FIRMS
            </div> */}
          </div>
        </div>
        {!!searchItems.length && (
          <ul className="search__dropdown dropdown-menu">
            {searchItems.map(item => (
              <Link
                key={item.sys?.id || item.id}
                to={`${handleLink(item)}`}
                className="search__link"
              >
                {searchType == 'article' && (
                  <div className="search__item">
                    <span className="search__title">{item.fields.title}</span>
                    {item.metadata.tags?.map(tag => (
                      <span className="search__tag">{tag.sys.id}</span>
                    ))}
                  </div>
                )}

                {searchType == 'resource' && (
                  <div className="search__item">
                    <div className="cursor-pointer">
                      {resourceIconHandler(item.fields?.resourceType)}
                    </div>
                    <span>{item.fields?.title}</span>
                  </div>
                )}
                {searchType == 'member' && (
                  <div className="search__item">
                    <img
                      className="search__img"
                      src={item.profile_photo_url}
                      alt={`${item.first_name} ${item.last_name}`}
                    />
                    <span>
                      {item.first_name} {item.last_name}
                    </span>
                  </div>
                )}
                {searchType == 'firm' && (
                  <div className="search__item">
                    <Flag country={item.country_code} />
                    <span>{item.name}</span>
                  </div>
                )}
              </Link>
            ))}
            {(searchType == 'member' || searchType == 'firm') && (
              <Link
                to={`/directory/${searchType}s?q=${searchValue}`}
                className="search__item search__link--see-more"
                onClick={handleClose}
              >
                See More
              </Link>
            )}
            {searchType == 'article' && (
              <Link
                to={`/search-results/articles/all-articles?q=${searchValue}`}
                className="search__item search__link--see-more"
                onClick={handleClose}
              >
                See More
              </Link>
            )}
            {searchType == 'resource' && (
              <Link
                to={`/downloads/resources/all-downloads?q=${searchValue}`}
                className="search__item search__link--see-more"
                onClick={handleClose}
              >
                See More
              </Link>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Search;
