import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorkerRegistration from './registerServiceWorker';

import App from 'App';

import { BrowserRouter as Router } from 'react-router-dom';

import './i18n';

import { StoreProvider } from './context/Store';
import { initialState, reducer } from './store/reducer';

const root = createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <HelmetProvider>
      <StoreProvider initialState={initialState} reducer={reducer}>
        <Router>
          <App />
        </Router>
      </StoreProvider>
    </HelmetProvider>
  </StrictMode>
);

serviceWorkerRegistration.unregister();
