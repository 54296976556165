import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useGetById } from 'hooks/useGetById';
import { getResourceById } from 'services/resources';

import ResourceDetail from 'common/components/Layout/ResourceDetail';

const ResourcePage = () => {
  const { slug } = useParams();

  const { data } = useGetById(getResourceById, slug) || [];

  return (
    <>
      <Helmet>
        <title>
          {data?.length && `Crowe Global - Resource: ${data[0].fields?.title || 'unknown'}`}
        </title>
      </Helmet>
      <div className="resources__container">
        {!!data?.length && <ResourceDetail resource={data[0]} />}
      </div>
    </>
  );
};

export default ResourcePage;
