import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getPendingUsers,
  approveUser,
  rejectUser,
  setRolePrivileges,
  sendPasswordResetEmail,
  deactivateUser,
} from 'services/user';
import { getFirmId, getUserId } from 'common/utils/localStorage';
import PendingUsers from 'common/components/Users/PendingUsers';
import FirmStaff from 'common/components/FirmDetail/FirmStaff';
import StaffAdmin from './StaffAdmin/StaffAdmin';
import FirmProfileEdit from 'common/components/FirmDetail/FirmProfileEdit';
import Offices from 'common/components/FirmDetail/Offices';
import Checklists from 'core/Checklists/Checklists';
import { useStore } from 'context/Store';
import { specialtiesIdToTitleHandle, specialtiesIdsAndTitle } from 'common/utils/role';
import { getFirm } from 'services/firm';
import NoResults from 'common/components/NoResults/NoResults';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from 'common/constants/toast';
import Spinner from 'common/components/Spinner/Spinner';

const Admin = () => {
  const [state] = useStore();

  const {
    hasAdminPermission,
    hasFirmAdminPermission,
    specialties,
    industries,
    specialty_categories,
    capabilities,
    vlpServices,
  } = state;

  const { t } = useTranslation('admin');
  const { t: gt } = useTranslation('global');

  const categoriesAndSpecialties = specialtiesIdToTitleHandle(
    capabilities,
    specialty_categories,
    specialties
  );

  const specialtiesIdsAndNames = specialtiesIdsAndTitle(specialties);
  const vlpServicesIdsAndNames = specialtiesIdsAndTitle(vlpServices);

  const [index, setIndex] = useState(0);
  const [firm, setFirm] = useState({});
  const [pendingUsers, setPendingUsers] = useState([]);

  const handleApprove = id => {
    return approveUser(id).then(() => {
      getPendingUsers(true);
    });
  };

  const handleReject = id => {
    return rejectUser(id).then(() => {
      getPendingUsers(true);
    });
  };

  const handleDeactivate = async id => {
    try {
      await deactivateUser(id);
      toast.info(t('userDeactivated'), TOAST_CONFIG);
      getFirm(getFirmId());
    } catch (error) {
      toast.error(gt('errorMessage'), TOAST_CONFIG);
    }
  };

  const handleSetRole = (id, email, roleName) => {
    return setRolePrivileges(id, roleName).then(() => {
      window.location.reload();
    });
  };

  const handleSendPasswordResetEmail = async (id, email) => {
    try {
      await sendPasswordResetEmail(id);
      toast.success(t('emailSent'), TOAST_CONFIG);
    } catch (err) {
      toast.error(gt('errorMessage'), TOAST_CONFIG);
    }
  };

  const handleFirm = async () => {
    const data = await getFirm(getFirmId(), { userstatus: 'all' });
    setFirm(data);
  };

  const handleTabs = tab => {
    if (tab) {
      setIndex(tab);
      handleFirm();
      return window.localStorage.setItem('adminTab', JSON.stringify(tab));
    }
    setIndex(0);
    return window.localStorage.setItem('adminTab', JSON.stringify(0));
  };

  const handlePendingUsers = async () => {
    const data = await getPendingUsers(hasAdminPermission);
    setPendingUsers(data);
  };

  const handleTitle = tab => {
    const helper = {
      0: 'Requests',
      1: 'Staff',
      2: 'Firm',
      3: 'Offices',
      5: 'All Firms Staff',
      7: 'Checklist',
    };
    return helper[tab];
  };

  useEffect(() => {
    document.title = `Crowe Global - Admin: ${handleTitle(index)}`;
  }, [index]);

  useEffect(() => {
    handleTabs(JSON.parse(window.localStorage.getItem('adminTab')));
    handlePendingUsers();
    handleFirm();
  }, [hasAdminPermission]);

  if (hasAdminPermission === null || hasFirmAdminPermission === null) {
    return <Spinner />;
  }

  if (hasAdminPermission === false && hasFirmAdminPermission === false)
    return (
      <NoResults
        icon="fa-sharp fa-solid fa-shield"
        title={t('unauthorizedAccess')}
        comment={t('noAuth')}
      />
    );

  return (
    <div className="layout__container">
      <h1 className="layout__title">
        {hasAdminPermission ? t('manageAllFirms') : t('manageMyFirm')}
      </h1>
      <div className="page__tabs">
        {(hasAdminPermission || hasFirmAdminPermission) && (
          <span
            className={`page__tab ${index === 0 ? 'page__tab--active' : ''}`}
            onClick={() => {
              handleTabs(0);
            }}
          >
            {t('requestsTab', { count: pendingUsers.length })}
          </span>
        )}
        {!hasAdminPermission && hasFirmAdminPermission && (
          <span
            className={`page__tab ${index === 1 ? 'page__tab--active' : ''}`}
            onClick={() => {
              handleTabs(1);
            }}
          >
            {t('staffTab')}
          </span>
        )}

        {hasAdminPermission && (
          <span
            className={`page__tab ${index === 5 ? 'page__tab--active' : ''}`}
            onClick={() => {
              handleTabs(5);
            }}
          >
            {t('staffTab')}
          </span>
        )}

        {hasFirmAdminPermission && !hasAdminPermission && (
          <span
            className={`page__tab ${index === 2 ? 'page__tab--active' : ''}`}
            onClick={() => {
              handleTabs(2);
            }}
          >
            {t('firmTab')}
          </span>
        )}
        {hasFirmAdminPermission && !hasAdminPermission && (
          <span
            className={`page__tab ${index === 3 ? 'page__tab--active' : ''}`}
            onClick={() => {
              handleTabs(3);
            }}
          >
            {t('officesTab')}
          </span>
        )}
        {hasFirmAdminPermission && !hasAdminPermission && (
          <span
            className={`page__tab ${index === 7 ? 'page__tab--active' : ''}`}
            onClick={() => handleTabs(7)}
          >
            {t('checklistTab')}
          </span>
        )}
      </div>

      {index === 0 && (
        <PendingUsers
          users={pendingUsers}
          handleApprove={handleApprove}
          handleReject={handleReject}
          handleSendPasswordResetEmail={handleSendPasswordResetEmail}
        />
      )}

      {index === 1 && !!firm.staff?.length && (
        <FirmStaff
          staff={firm.staff
            .filter(user => user.status === 'APPROVED')
            .map(user => {
              return {
                ...user,
                id: user.user_id,
                first_name: user.contact_first_name,
                last_name: user.contact_last_name,
                email: user.contact_email,
                profile_photo_url: user.contact_photo_url,
              };
            })}
          noGlobal={true}
          handleSetRole={handleSetRole}
          handleSendPasswordResetEmail={handleSendPasswordResetEmail}
          handleDeactivate={handleDeactivate}
          handleApprove={handleApprove}
        />
      )}

      {index === 5 && (
        <StaffAdmin
          handleSetRole={handleSetRole}
          handleSendPasswordResetEmail={handleSendPasswordResetEmail}
          handleDeactivate={handleDeactivate}
          handleApprove={handleApprove}
        />
      )}

      {index === 2 && firm?.firm && (
        <FirmProfileEdit
          firm={firm}
          industries={industries}
          specialtyCategories={specialty_categories}
          categoriesAndSpecialties={categoriesAndSpecialties}
          specialtiesIdsAndNames={specialtiesIdsAndNames}
          vlpServicesIdsAndNames={vlpServicesIdsAndNames}
          vlpServices={vlpServices}
        />
      )}

      {index === 3 && !!firm?.offices?.length && <Offices offices={firm.offices} editMode />}

      {index === 7 && firm?.firm && (
        <Checklists
          userid={getUserId()}
          firmid={getFirmId()}
          verified_by_user_id={firm.firm.verified_by_user_id}
          verified_at={firm.firm.verified_at}
          verify_name={firm.staff.filter(s => s.user_id === firm.firm.verified_by_user_id)}
        />
      )}
    </div>
  );
};

export default Admin;
