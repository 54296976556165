import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useStore } from 'context/Store';

const UserTracker = () => {
  const [state] = useStore();
  const { currentUser } = state;
  const { id, role, firm_roles, network_roles, firm, firm_id, country, office_city } = currentUser;

  const updateUserProfile = () => {
    mixpanel.identify(id); // Ensure all events are linked to this user
    mixpanel.people.set({
      Role: role,
      'Firm roles': firm_roles,
      'Network roles': network_roles,
      FirmId: firm_id?.toString(),
      FirmName: firm,
      FirmCountry: country,
      FirmCity: office_city,
      last_login: new Date().toISOString(),
    });

    // Persist role for future events (useful for event properties)
    mixpanel.register({
      Role: role,
      FirmId: firm_id?.toString(),
      FirmName: firm,
      FirmCountry: country,
      FirmCity: office_city,
    });
  };

  useEffect(() => {
    updateUserProfile();
  }, [currentUser]);

  return null; // No UI, just tracking
};

export default UserTracker;
