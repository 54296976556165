import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { trackEvent } from 'common/utils/mixpanel';

const trackPageView = (path, title) => {
  trackEvent('Page Viewed', {
    path,
    'Page Title': title,
    timestamp: new Date().toISOString(),
  });
};

const PageTracker = () => {
  const location = useLocation();
  const title = document.title;
  const lastTrackedPath = useRef(null);

  useEffect(() => {
    if (lastTrackedPath.current !== location.pathname) {
      trackPageView(location.pathname, title);
      lastTrackedPath.current = location.pathname;
    }
  }, [location.pathname, title]);

  return null;
};

export default PageTracker;