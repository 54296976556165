import { useState } from 'react';
import { resourceIconHandler, fileButtonHandler } from 'common/helpers/file';
import Message from 'common/components/Layout/Message';
import Prompt from 'common/components/Prompt/Prompt';
import { Link } from 'react-router-dom';
import { isVideo } from 'common/helpers/file';
import { trackDownload } from 'common/utils/mixpanel';

const Resource = ({ resource, tagList }) => {
  const [prompt, setPrompt] = useState(false);

  const url = resource?.fields?.url || resource?.fields?.media?.fields.file.url;

  const resourceType = resource.fields.resourceType;
  const resourceIsVideo = isVideo(resourceType);

  const link = `/resource/${resource.fields.slug ?? `.${resource.sys.id}`}`;

  const {
    fields: { title, description, external },
    metadata: { tags },
  } = resource;

  const handleTags = tags => {
    return `${tags.map(tag => tagList[tag.sys.id]).join(' / ')}`;
  };

  const togglePrompt = () => {
    setPrompt(!prompt);
  };

  const handleButton = () => {
    return external ? (
      <>
        <div className="cursor-pointer" onClick={togglePrompt}>
          {resourceIconHandler(resourceType)}
        </div>
        <button className="resource__btn" onClick={togglePrompt}>
          {fileButtonHandler(resourceType)}
        </button>
        {prompt && (
          <Prompt>
            <Message url={url} handleClose={togglePrompt} />
          </Prompt>
        )}
      </>
    ) : (
      <>
        <div onClick={() => trackDownload(title, url, resourceIsVideo, link)}>
          {resourceIconHandler(resourceType)}
        </div>
        <div
          className="resource__btn"
          onClick={() => trackDownload(title, url, resourceIsVideo, link)}
        >
          {fileButtonHandler(resourceType)}
        </div>
      </>
    );
  };

  if (!url) return null;

  return (
    <div className="resource__container rounded">
      <div className="resource__body">
        {resource.fields.priority && <i className="icon-thumbtack resource__thumbtack" />}
        {!!tags.length && (
          <>
            <p className="resource__tags">{handleTags(tags)}</p>
            <span className="resource__separator" />
          </>
        )}
        <Link to={link}>
          <h2 className="resource__title ellipsis-3l">{title}</h2>
          <p className="resource__description">{description}</p>
        </Link>
        <div className="resource__action-placeholder" />
        <div className="resource__action">{handleButton()}</div>
      </div>
    </div>
  );
};

export default Resource;
